let videoThumb = document.querySelectorAll('.videoThumb__link');
const youtubeArea = document.querySelector('#youtubeVideoArea');


if (videoThumb !== null) {
    videoThumb.forEach((thumb) => {
        thumb.addEventListener('click', function () {
            videoThumb.forEach((el) => {
                el.classList.remove('current');
            })
            thumb.classList.add('current');
            const vID = thumb.getAttribute('data-id');
            youtubeArea.src = 'https://www.youtube.com/embed/' + vID + '?si=wNK5xEkr1sMW2Zx9'

        })
    })
}

