var gnavButton = document.querySelector('#btnAccordion');
var target = document.querySelector('#navigation');

if (gnavButton) {
    gnavButton.addEventListener('click', () => {
        gnavButton.classList.toggle('is-active');
        target.classList.toggle('is-open');
        var waiaria = gnavButton.getAttribute('aria-expanded');
        if (waiaria === 'false') {
            gnavButton.setAttribute('aria-expanded', 'true');
        } else {
            gnavButton.setAttribute('aria-expanded', 'false');
        }
    });

}
